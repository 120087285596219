/* You can add global styles to this file, and also import other style files */

//  * {
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
// }

/*.container-fluid {
    padding: 0 !important;
    margin: 0 !important;
}*/

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@angular/material/theming";
body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}
html,
body {
  overscroll-behavior: none;
  overflow-x: hidden;
}
.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.modal-open .modal {
  z-index: 9999999;
}

.elevate#onboarding-active {
  @include mat-elevation-transition;
  @include mat-elevation(8);
  z-index: 10000;
}

.elevate {
  background: white;
  position: relative;
}

.my-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  color: #fff !important;
  background: transparent !important;
  font-size: 1.5rem !important;
}

.unlock-feature {
  opacity: 0.5;
  pointer-events: none;
}

.unlock-feature.active {
  opacity: 1;
  pointer-events: all;
}

@import "fonts.scss";
@import "~katex/dist/katex.css";
// H1 / Heading MainHelvetica Now Text- MediumFont size 32Pxline Hight - 46PX
$primary-color: #0fd0c1;
$title-color: #000000;
$sub-title-color: #7f7f7f;
$border-color: #dedede;
$red-6b: #ff6b6b;
$opecity-color: #e8fdfb;
.signin-button,
#appleid-signin > div {
  width: 210px !important;
  height: 40px !important;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background: $primary-color;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::ng-deep.katex {
  // font: normal 24px helvetica-regular;
  font-family: "helvetica-regular" !important;
  line-height: 1.6;
  text-indent: 0;
  font-weight: 500 !important;
  text-rendering: auto;
  font-size: 18px !important;

  // font: 24px helvetica-regular;
  // font-family: helvetica-regular;
  // line-height: 2.2;
  // text-indent: 0;
  // font-weight: 500!important;
  // text-rendering: auto;
  // font-size: 30px!important;
}

.tutors-details .tab ul li {
  list-style: disc !important;
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  color: #fff !important;
  background: transparent !important;
  font-size: 1.6rem !important;
  font-weight: 600;
}

// ::ng-deep.katex .base {
//     font-size: 24px;
// }
.font-medium {
  font-family: "helvetica-medium";
}

.font-regular {
  font-family: "helvetica-regular";
}

.font-bold {
  font-family: "helvetica-bold";
}

a {
  text-decoration: none !important;
}

ul li {
  list-style: none;
}

.heading {
  font-size: 24px;
  line-height: 46px;
}

.sub-heading {
  font-size: 20px;
  line-height: 40px;
}

.text-one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.p-large {
  font-size: 16px;
  // line-height: 40px;
}

vg-player video {
  width: 3 0% !important;
  margin: auto;
}

// .p-medium {
//     font-size: 18px;
// }
p {
  margin-bottom: 0;
}

.text-muted {
  color: $sub-title-color;
}

a {
  text-decoration: none;
  color: #0fd0c1;
}

.mb-bottom {
  margin-bottom: 1.8rem;
}

// h4 {
//     text-transform: lowercase;
// }
// h4:first-letter {
//     text-transform: uppercase;
// }
button.primary-btn {
  opacity: 1;
  background: #0fd0c1 0% 0% no-repeat padding-box;
  width: fit-content;
  outline: none;
  box-shadow: navajowhite;
  border: 1px solid #0fd0c1;
  padding: 12px 30px;
  font-weight: 500;
  border-radius: 50rem;
  // font-size: 20px;
  font-family: "helvetica-medium";
}

.outline-btn {
  opacity: 1;
  background: #ffff 0% 0% no-repeat padding-box;
  width: fit-content;
  outline: none;
  box-shadow: navajowhite;
  border: 2px solid #0fd0c1;
  padding: 10px 30px;
  font-weight: 500;
  border-radius: 50rem;
  // font-size: 20px;
  font-family: "helvetica-medium";
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.colored {
  color: $primary-color;
}

.form-control {
  border-radius: 10px;
  padding: 12px !important;
  height: auto;
}

.form-control:focus {
  color: #212529;
  background-color: #fff !important;
  border-color: #0fd0c1 !important;
  outline: 0;
  box-shadow: 0 0 0 0.05rem rgba(13, 110, 253, 0.25) !important;
}

form {
  label {
    color: $sub-title-color;
    font-family: "helvetica-regular";
  }
  input::placeholder {
    // text-transform: capitalize!important;
    color: black !important;
    font-weight: 300 !important;
    font-family: "helvetica-regular";
  }
}

.card {
  box-shadow: -4px 7px 60px #a7b1c326;
  border-radius: 24px !important;
  background-color: white;
  border: 0 !important;
  // margin: 1rem;
  padding: 1rem;
  .card-body {
    padding: 0;
  }
}

.modal-content {
  border-radius: 12px !important;
  border: 0 !important;
}

.modal-header .btn-close {
  background: #0fd0c1;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.modal-dialog.modal-dialog-centered {
  padding: 0;
}

input[type="text"]:focus {
  color: black;
}

.progress-inner {
  border-radius: 0px !important;
  color: #ff6b6b !important;
  background-color: #ff6b6b !important;
}

.progress-outer {
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  border: 0px !important;
  background-color: #d2d2d2 !important;
}

.progress {
  border-radius: 0px !important;
  height: 0.7rem !important;
}

.streakModal {
  .modal-content {
    background-color: transparent;
    display: flex;
    align-content: center;
    align-items: center;
  }
}
.useMobileModal {
  overflow-y: clip;
  margin: 0px !important;
  padding: 0px !important;
  height: 100vh;
  .modal-content {
    background-color: transparent;
    display: flex;
    align-content: center;
    align-items: center;
    background-color: transparent;
    position: absolute;
    bottom: 0px;
  }
}

.custom-checkbox {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked {
      + {
        label {
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            left: 3px;
            width: 14px;
            border-width: 0 2px 2px 0;
            height: 14px;
            background: #00f8e5;
            border-radius: 2px;
          }
        }
      }
    }
  }
  label {
    position: relative;
    cursor: pointer;
    color: black;
    font-size: 16px;
    white-space: break-spaces;
    text-transform: inherit;
    // font-weight: 500;
    font-size: 18px;
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #00f8e5;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-top: -2px;
    }
  }
}

.hide {
  display: none;
}

.our-tutors .owl-nav {
  position: absolute;
  right: 0;
  top: -72px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #0fd0c1;
  color: #fff;
  text-decoration: none;
}

.our-tutors .owl-carousel .owl-prev,
.our-tutors .owl-carousel .owl-next {
  font-size: 25px !important;
  margin: 5px !important;
  padding: 0 !important;
  background: #efeff1 !important;
  display: inline-block;
  border-radius: 12px;
  width: 42px;
  height: 42px;
  color: $primary-color !important;
  border-radius: 12px !important;
}

.super-tutors .carousel button.slick-prev {
  left: -75px;
}

.super-tutors .carousel button.slick-next {
  right: -75px;
}

.super-tutors .carousel .slick-dots li button:before {
  font-size: 12px;
}

.super-tutors .carousel .slick-dots li.slick-active button:before {
  color: $primary-color;
}

.super-tutors .carousel .slick-dots {
  bottom: -45px;
}

.display {
  display: flex;
}

.notification-drop::-webkit-scrollbar {
  display: none;
}

.questions .card p {
  margin-bottom: 0 !important;
}

.slick-prev,
.slick-next {
  z-index: 1 !important;
  height: 42px !important;
  width: 42px !important;
  border-radius: 12px;
  background-color: $primary-color !important;
  color: white !important;
}

.slick-prev:before {
  content: "\f053" !important;
}

.slick-next:before {
  content: "\f054" !important;
}

.slick-prev:before,
.slick-next:before {
  color: white !important;
  font-family: fontawesome !important;
}

.practice-slider .slick-prev,
.practice-slider .slick-next {
  top: 41% !important;
}

.practice-slider .slick-prev {
  left: -65px;
}

.practice-slider .slick-next {
  right: -65px;
}

.flashcard-slider .slick-prev {
  left: 0;
}

.flashcard-slider .slick-next {
  right: 0;
}

.video-player vg-player {
  max-height: 600px;
  border-radius: 23px;
}

.user_details p ul {
  padding-left: 0 !important;
}

.lang_selections {
  .lang_selection {
    padding: 4px 10px;
    font-size: 16px;
    float: right;
    right: 45px;
    top: 40px;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #ced4da;
    box-shadow: none;
    outline: none;
    z-index: 600;
    cursor: pointer;
    max-width: 140px;
    background: #fff;
  }
  .dropdown {
    img {
      width: 30px;
    }
  }
  .dropdown-menu {
    hr {
      margin: 2.5px;
      border-top: 1px solid #a9a6a6;
      padding: 0px;
      width: 100%;
    }
    color: #444;
    img {
      width: 25px;
    }
    a {
      color: #000 !important;
      padding: 3px 10px;
    }
  }
}

.dropdown-menu.show {
  min-width: 140px !important;
}
@media screen and (max-width: 768px) {
  .lang_selections {
    margin-top: 0% !important;
  }
}

@media screen and (max-width: 1280px) {
  .heading {
    font-size: 24px;
    line-height: 40px;
  }
  .sub-heading {
    font-size: 18px;
    line-height: 30px;
  }
  .p-large {
    font-size: 18px;
    line-height: 30px;
  }
  .p-medium {
    font-size: 16px;
    line-height: 30px;
  }
  p {
    line-height: 30px;
  }
  h3 {
    font-size: 22px;
  }
  .lang_selections .lang_selection {
    right: 25px;
    top: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .heading {
    font-size: 20px !important;
    line-height: 35px;
  }
  .lang_selections .lang_selection {
    right: 20px;
    top: 15px;
  }
  .sub-heading {
    font-size: 16px;
  }
  .p-large {
    font-size: 16px;
  }
  .p-medium {
    font-size: 14px;
  }
  .card {
    margin: 0 0 1rem 0 !important;
    box-shadow: none;
  }
  .video-section.mb-bottom {
    margin-bottom: 0;
  }
  .our-tutors .owl-nav {
    position: absolute;
    right: 0;
    top: -72px;
  }
  .our-tutors .owl-carousel .owl-prev,
  .our-tutors .owl-carousel .owl-next {
    font-size: 16px !important;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px !important;
  }
  button.primary-btn {
    font-size: 16px;
  }
  .outline-btn {
    font-size: 16px;
  }
  .custom-checkbox {
    input {
      &:checked {
        + {
          label {
            &:after {
              content: "";
              width: 12px;
              height: 12px;
              border-width: 0 2px 2px 0;
            }
          }
        }
      }
    }
    label {
      font-size: 16px;
      &:before {
        content: "";
        width: 18px;
        height: 18px;
      }
    }
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .super-tutors .carousel button.slick-prev {
    left: -60px;
  }
  .super-tutors .carousel button.slick-next {
    right: -60px;
  }
}
