
@font-face {
    font-family: "helvetica-regular";
    src: url("https://studeo-web-fonts.s3.eu-west-2.amazonaws.com/HelveticaNowText-Regular.ttf");
}

@font-face {
    font-family: "helvetica-medium";
    src: url("https://studeo-web-fonts.s3.eu-west-2.amazonaws.com/HelveticaNowText-Medium.ttf");
}

@font-face {
    font-family: "helvetica-bold";
    src: url("https://studeo-web-fonts.s3.eu-west-2.amazonaws.com/HelveticaNowText-Bold.ttf");
}
